ç
<template>
  <v-navigation-drawer
    :class="{'d-none d-md-block': !fromDrawer}"
    :width="fromDrawer ? '100%':200"
    class="cb-sidebar elevation-4 pb-8"
    fixed
    permanent
  >
    <v-row
      class="ma-0 justify-center pa-3"
      dense

      style="height: 47px"
    >
      <v-img
        class="cb-sidebar-img"
        contain
        src="../../assets/cts-logo-white.png"
        @click="goTo('home')"
      ></v-img>
    </v-row>
    <v-row>
      <v-text-field
        ref="search"
        v-model="routeSearch"
        class="ma-0 cb-search-sidebar"
        clearable
        dense
        hide-details
        placeholder="Buscar página"
        @keyup.enter="goTo(Object.values(profileRoutes)[0][0].name)"
      />
      <button
        v-shortkey="$shortKeys.view.command"
        class="d-none"
        @shortkey="$refs.search.focus()"
      />
    </v-row>
    <v-row>
      <v-col class="pa-0">
        <v-expansion-panels
          v-model="selectedPanels"
          class="cb-sidebar-panels"
          multiple
          tile
        >
          <v-expansion-panel
            v-for="(category,index) in Object.keys(profileRoutes)"
            :key="index"
            class="pa-0 ma-0"
          >
            <v-expansion-panel-header
              class="pa-0 px-2"
            >
              <AppCenterIcon
                :name="getCategoryIcon(category)"
                color="bg_clear"
                height="20px"
                width="20px"
              />
              <span class="ml-2">{{category}}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list
                class="pa-0"
                dense
                nav
                width="100%"
              >
                <v-list-item-group
                  v-model="selectedItem"
                  color="primary"
                >
                  <v-list-item
                    v-for="(route, index) in  profileRoutes[category]"
                    :key="index"
                    :to="route.path"
                    class="ma-0"
                  >

                    <v-list-item-content>
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-list-item-title
                            class="pl-2"
                            v-on="on"
                            v-text="route.meta.title_short"
                          ></v-list-item-title>
                        </template>
                        <span>{{route.meta.title}}</span>
                      </v-tooltip>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon
                        class="d-none"
                        style="font-size: 24px !important;"
                      >arrow_right
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-icon
      v-if="$env.name != 'pro'"
      :style="'color:' + $env.color + ' !important'"
      class="cb-env-circle"
    >circle
    </v-icon>
    <div class="cb-env-line"></div>
    <v-row
      v-if="$env.name != 'pro' && expiration"
      :style="'color:' + $env.color"
      class="cb-sidebar-footer justify-center cts-w-100 pb-1"
    >
      <v-progress-linear
        :color="expirationDifference < 20 ? 'error': $env.color"
        :title="'Expiración de la sesión ' + expiration.from($moment())"
        :value="expirationDifference"
        buffer-value="100"
        stream
      ></v-progress-linear>
      <h1 class="text-uppercase font-weight-bold cb-font-size-3 env_color--text mt-1">
        {{$env.complete_name}}
      </h1>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import helpers from '@/plugins/helpers'
import service_user from '@/services/service_user'
import AppCenterIcon from '@/components/repository/app-center-icon'
import {ADMIN, B2B, COMMSERVICES, EXPLORERS, MONITORING, MANAGEMENT, PROTOCOL} from '@/constants/categories'
import store from '@/store'
import vuex_authentication from '@/store/modules/vuex_authentication'
import jwt_decode from "vue-jwt-decode";

export default {
  name: 'Sidebar',
  components: {AppCenterIcon},
  data() {
    return {
      drawer: false,
      helpers,
      selectedItem: [],
      selectedPanels: [],
      routeSearch: null,
      timeout: null,
      timeoutSeconds: 0,
      expiration: null
    }
  },
  props: ['environment', 'authentication', 'mini', 'fromDrawer'],
  computed: {
    expirationDifference() {
      if (!this.expiration) {
        return null
      }
      let diff = this.expiration.diff(this.$moment(), 'seconds')
      return diff * 100 / 900

    },
    profileRoutes() {
      let routes = this.$router.options.routes.filter(
        (r) =>
          r.meta.navigable && service_user.checkGroupPermission(r.meta.perm)
      )

      if (this.routeSearch != null && this.routeSearch.length != 0) {
        routes = routes.filter(r => r.meta.title.toLowerCase().indexOf(this.routeSearch.toLowerCase()) != -1 ||
          r.meta.title_short.toLowerCase().indexOf(this.routeSearch.toLowerCase()) != -1)
        this.openCategories(routes)
      }
      let categories = {
        general: []
      }

      routes.forEach(r => {
        if (r.meta.category != null) {
          if (categories[r.meta.category] == null) {
            categories[r.meta.category] = []
          }
          categories[r.meta.category].push(r)
        } else {
          categories['general'].push(r)
        }
      })
      if (categories['general'].length === 0) {
        delete categories['general']
      }
      return categories
    },
  },
  watch: {
    $route() {
      setTimeout(() => {
        this.getSessionExpiration()
      }, 2000)
    }
  },
  created() {
    this.openCategories(null)
    this.getSessionExpiration()
  },
  methods: {
    getSessionExpiration() {
      const act = this.$cookies.get('act')
      if (act) {
        this.expiration = null

        let token = jwt_decode.decode(act)
        if (token) {
          this.expiration = this.$moment.unix(token.exp)
          if (this.expiration.isAfter(this.$moment())) {

            setTimeout(() => {
              this.getSessionExpiration()
            }, 60000)
          } else {
            store.dispatch(
              vuex_authentication.constants.NAMESPACE +
              '/' +
              vuex_authentication.constants.A_SET_LOGOUT,
              null
            )
          }
        } else {
          this.expiration = null
        }
      }

    },
    getCategoryIcon(category) {
      switch (category) {
        case PROTOCOL:
          return 'protocol'
        case B2B:
          return 'b2b'
        case ADMIN:
          return 'admin'
        case MONITORING:
          return 'monitoring'
        case EXPLORERS:
          return 'explorers'
        case COMMSERVICES:
          return 'commservices'
        case MANAGEMENT:
          return 'work'
      }
    },
    openCategories(categories) {
      if (categories == null) {

        let openCat = Object.values(this.profileRoutes).findIndex(pr => pr.find(prr => prr.name === this.$route.name) != null)
        if (openCat == -1) openCat = 0
        this.selectedPanels = [openCat]
      } else {
        let panels = []
        for (let i = 0; i < Object.keys(categories).length; i++) {
          panels.push(i)
        }
        this.selectedPanels = panels
      }
    },
    changeStatusSidebar() {
      this.mini = !this.mini
      this.$refs.list_routes.focus()
    },
    goTo(route) {
      this.routeSearch = null
      helpers.redirect(route)
    }
  },
  filters: {
    categoryRouteList(val) {
      let str = []
      val.forEach(v => {
        str.push(v.meta.title_short)
      })
      return str.join(', ')
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/styles/resources/colors";
@import "../../assets/styles/resources/variables";

.cb-sidebar .cb-sidebar-page-list {
  margin-top: 50px;
  height: calc(100% - 50px);
  background-color: $color_app_bg_dark !important;
}

.cb-sidebar {
  background-color: $color_app_primary !important;
  background-image: url('../../assets/bg.png') !important;
  background-position: left 0 top 0;
  background-repeat: no-repeat;
  background-size: 256px 556px;
}

.cb-sidebar-panels {
}

.cb-sidebar .v-list-item__title {
  font-size: $font-size-4 !important;
  font-weight: 500;
  line-height: 20px !important;
  margin-bottom: 0px !important;

}

.cb-sidebar .v-list-item__icon .v-icon {
  color: $color_app_contrast_low;
}

.cb-sidebar .v-list-item__title {
  color: $color_app_contrast_low !important;
  font-weight: 400 !important;
}

.cb-sidebar-footer {
  position: absolute;
  bottom: 0;
  background-color: rgba(white, 0.9);
}

.cb-sidebar-footer .v-list-item__icon .v-icon {
  color: $color_app_contrast_low;
}

.cb-sidebar-footer .v-list-item__title {
  color: $color_app_contrast_low !important;
}

.v-navigation-drawer i {
  font-size: 18px !important;
}

.cb-sidebar .cb-sidebar-select-profile span {
  font-weight: 400 !important;
}

.cb-sidebar .cb-sidebar-select-profile {
  background-color: $color_app_contrast_medium !important;
}

.cb-sidebar-platform-list {
}

.cb-sidebar-platform-list .v-list-item__title {
  color: $color_app_contrast_high;
}

.cb-sidebar .v-list-item:hover {
  background-color: rgba($color_app_primary, 0.9) !important;
}

.cb-sidebar .v-list-item {
  padding-left: 28px;
  padding-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.cb-sidebar .v-list-item:hover i {
  color: $color_app_contrast_low !important;
}

.cb-sidebar .v-list-item--active {
  background: $color_app_bg_clear;
  border-radius: 4px;
  margin-right: 25px !important;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: margin-right 0.5s cubic-bezier(0.63, 0.57, 0.28, 1.12);
}

.cb-sidebar .v-list-item--active .v-list-item__content {
  min-width: 73%;

}

.cb-sidebar .v-list-item .v-list-item__content {
  padding: 4px 0 !important;

}


.cb-sidebar .v-list-item--active:hover {
  background-color: $color_app_bg_clear !important;
}

.cb-sidebar .v-list-item--active:before {
  opacity: 0 !important;
}

.cb-sidebar .v-list-item--active .v-list-item__title {
  color: $color_app_primary !important;
}

.cb-sidebar .v-list-item--active .v-list-item__icon i {
  color: $color_app_primary !important;
}

.cb-sidebar i {
  color: $color_app_contrast_low !important;
}


.cb-sidebar .v-list-item__icon {
  margin: 0 !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  margin-right: 6px !important;
}

.cb-sidebar .cb-env-circle {
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: 15px !important;
}

.cb-sidebar .cb-env-line {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 5px;
}


.cb-sidebar .v-expansion-panel:before {
  box-shadow: none !important;
  background-color: rgba($color_app_bg_clear, 0.12) !important;
}

.cb-sidebar .v-expansion-panel-content__wrap {
  padding: 0;
}


.cb-sidebar .v-expansion-panel-header span {
  color: $color_app_bg_clear;
  font-weight: 200;
  line-height: 14px;
}


.cb-sidebar .v-expansion-panel {
  background-color: transparent !important;
}

.cb-sidebar .v-expansion-panel-header {
  min-height: auto;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.cb-sidebar .v-expansion-panel-header--active {
  background-color: transparent !important;
}

.cb-sidebar .v-expansion-panel-header .subtitle {
  font-weight: normal;
  font-style: italic !important;
  opacity: 0.7;
  padding-top: 4px;
  font-size: 11px !important;
}

.cb-sidebar .v-divider {
  border-bottom: 1px solid rgba($color_app_bg_clear, 0.3) !important;
}

.cb-sidebar-img {
  cursor: pointer;

  & .v-image {

  }
}

.cb-sidebar .cb-search-sidebar {
  width: 100%;
}

.cb-sidebar .cb-search-sidebar input {
  padding-left: 5px !important;
  color: $color_app_clear;
  border: none !important;
}

.cb-sidebar .cb-search-sidebar .v-icon {
  color: white !important;
}

.cb-sidebar .cb-search-sidebar input::placeholder {
  padding-left: 5px;
  color: $color_app_contrast_medium !important;
  font-style: italic;
}

.cb-sidebar .cb-search-sidebar > .v-input__control > .v-input__slot:before {
  border: none !important;
  padding-left: 5px;
}
</style>
