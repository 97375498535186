import {
  P_MANAGEMENT_DOCUMENT_PROCESS,
  P_MANAGEMENT_DP,
  P_PROTOCOL_NOTARIAL_DEPOSIT
} from '@/constants/group_permissions'
import icons from "@/constants/icons";
import {MANAGEMENT} from "@/constants/categories";
import DeveloperPortal from "@/views/administration/DeveloperPortal.vue";

const ProcessManagement = () => import(/* webpackChunkName: "ProcessManagement"*/ '@/views/management/ProcessManagement.vue')
const NotarialDeposit = () => import(/* webpackChunkName: "ProcessManagement"*/ '@/views/management/NotarialDeposit.vue')

const ROOT = '/management/'
const CATEGORY = MANAGEMENT
export default [
  {
    path: ROOT + 'dev_portal',
    name: 'dev_portal',
    component: DeveloperPortal,
    meta: {
      perm: P_MANAGEMENT_DP,
      title: 'Gestión de usuarios del portal del integrador',
      title_short: 'DEV Portal',
      navigable: true,
      category: CATEGORY,
      icon: 'computer',
      requiresAuth: true
    },
  },
  {
    path: ROOT + 'processes',
    name: 'processes_pkc',
    component: ProcessManagement,
    meta: {
      perm: P_MANAGEMENT_DOCUMENT_PROCESS,
      title: 'Gestión de procesos',
      title_short: 'Procesos',
      navigable: true,
      category: CATEGORY,
      icon: 'work',
      requiresAuth: true
    },
  },
  {
    path: ROOT + 'notarial_deposit',
    name: 'notarial_deposit',
    component: NotarialDeposit,
    meta: {
      perm: P_PROTOCOL_NOTARIAL_DEPOSIT,
      icon: icons.I_NOTARIAL_DEPOSIT,
      title: 'Depósito Notarial',
      title_short: 'D. Notarial',
      navigable: true,
      category: CATEGORY,
      requiresAuth: true
    }
  },
]
