<template>
  <v-dialog
    v-model="open"
    max-width="1200px"
    persistent
    transition="dialog-bottom-transition"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <v-card
      v-if="commstrackerDomainInfo"
      class="cb-edit-dialog pa-0"
    >
      <card-title
        :close="true"
        :icon="$icons.I_COMMSTRACKER"
        title="Dominio de Commstracker"
        @close="$emit('close')"
      />
      <v-card-text
        class="pa-0 pb-4"
      >
        <v-row>
          <v-col
            class="pa-6"
            cols="4"
          >
            <v-card
              class="cb-card-info"
              outlined
            >
              <v-row class="pa-2 mt-2">
                <v-icon
                  :color="errors.length != 0 ? 'error':'success'"
                  class="mr-2"
                  small
                >{{errors.length != 0 ? 'warning' : 'check_circle'}}
                </v-icon>
                <span class="cb-font-size-3">Estado del dominio <strong>{{
                    commstrackerDomain['domain'] == null ? '' : commstrackerDomain['domain']
                  }}</strong></span>
              </v-row>
              <v-row
                v-if="errors.length != 0"
                class="my-auto pl-8"
              >
                <p
                  v-for="(error,index) in errors"
                  :key="index"
                  class="font-weight-medium bg_dark--text"
                >
                  {{error.text}}
                </p>
              </v-row>
              <v-row v-else>
                <p
                  class="font-weight-medium pl-8 bg_dark--text"
                >La configuración del dominio es correcta</p>
              </v-row>
            </v-card>

          </v-col>
          <v-col
            class="pa-2 pt-4"
            cols="8"
          >
            <v-row class="pt-2 cb-section-title">
              <span>Parámetros de configuración</span>
            </v-row>
            <v-row>
              <v-col>
                <RowPropertyConfig
                  v-for="prop in domainProperties"
                  :key="prop.id"
                  :error="errors.find(e => e.field == prop.id)"
                  :is-editing="isEditing"
                  :property="prop"
                  :value="commstrackerDomainInfo[prop.id]"
                  @change="changeProp"
                  @delete="deleteDomain"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <dialog-card-footer
        :close-button-visible="true"
        :delete-button-visible="true"
        :edit-button-visible="!isEditing && service_user.checkGroupPermission(P_COMMSERVICES_COMMSTRACKER_EDIT)"
        :save-button-disabled="!paramsValid"
        :save-button-visible="isEditing"
        delete-button-custom-text="Borrar dominio"
        @close="$emit('close')"
        @delete="deleteDomain"
        @edit="isEditing=true"
        @save="save()"
      />
    </v-card>
    <ConfirmDialog ref="confirm"/>
  </v-dialog>
</template>

<script>
import RowPropertyConfig from '@/components/repository/RowPropertyConfig'
import types from '@/constants/types'
import helpers from '@/plugins/helpers'
import CardTitle from '@/components/repository/cards/CardTitle'
import service_user from '@/services/service_user'
import {P_COMMSERVICES_COMMSTRACKER_EDIT} from '@/constants/group_permissions'
import service_protocol from '@/services/service_protocol'
import DialogCardFooter from '@/components/repository/dialogs/DialogCardFooter'
import ConfirmDialog from '@/components/repository/dialogs/ConfirmDialog'

export default {
  name: 'CommstrackerDomainCreateOrUpdateDialog',
  components: {
    ConfirmDialog,
    DialogCardFooter,
    CardTitle,
    RowPropertyConfig
  },
  props: {
    commstrackerDomain: {
      type: [Object, String],
      default: null
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      service_user,
      helpers,
      P_COMMSERVICES_COMMSTRACKER_EDIT,
      newDomain: false,
      commstrackerDomainInfo: null,
      open: true,
      isEditing: false,
      step: 0,
      changesPending: {},
      domainProperties: [
        {
          id: 'domain',
          name: 'Dominio',
          disabled: this.commstrackerDomain != 'new' && this.commstrackerDomain != null,
          description: 'Nombre que va a tener el dominio en la plataforma',
          type: types.STRING,
          extra: {
            maxLength: 50
          }
        },
        {
          id: 'description',
          name: 'Descripción',
          description: 'Descripción del dominio',
          type: types.STRING,
          extra: {
            maxLength: 200
          }
        },
        {
          id: 'default_redirect',
          name: 'Redirección por defecto',
          description: 'Dirección a la que se redirigirá al usuario si no existe el enlace',
          type: types.STRING,
          extra: {
            maxLength: 200
          }
        },
        {
          id: 'callback_url',
          name: 'Url de callback',
          description: 'Dirección a la que se realizarán los callbacks',
          type: types.STRING,
          extra: {
            maxLength: 200
          }
        },
        {
          id: 'blocked',
          name: 'Bloqueado',
          description: 'Indica si el dominio está bloqueado y no se puede utilizar',
          type: types.CUSTOM_CHIP_GROUP,
          extra: {
            items: types.COMMSTRACKER_DOMAIN_VALUES,
            item_text: 'text',
            item_value: 'code'
          }
        }
      ],
      confirmSave: false,
    }
  },
  computed: {
    paramsValid() {
      return this.errors.length == 0 && this.changesPending != null && Object.keys(this.changesPending) != 0
    },
    domainEntities() {
      if (!this.newDomain) {
        return [{
          title: 'URL',
          id: this.commstrackerDomainInfo.domain
        }, {
          title: 'Descripción',
          id: this.commstrackerDomainInfo.description
        }, {
          title: 'URL Callback',
          id: this.commstrackerDomainInfo.callback_url
        }, {
          title: 'Redirección',
          id: this.commstrackerDomainInfo.default_redirect
        }]
      }
      return null

    },
    errors() {
      let errors = []
      if (this.changesPending != null && this.isEditing || this.newDomain) {

        let domain = this.changesPending['domain']
        let description = this.changesPending['description']
        let callback_url = this.changesPending['callback_url']
        let default_redirect = this.changesPending['default_redirect']

        if ((domain != null && domain.length < 3) || (helpers.nullOrEmpty(this.commstrackerDomainInfo.domain) && helpers.nullOrEmpty(domain))) {
          errors.push({
            section: 'general',
            field: 'domain',
            text: 'El nombre no puede estar vacío ni ser inferior a 3 caractéres'
          })
        }
        if ((description != null && description.length < 3) || (helpers.nullOrEmpty(this.commstrackerDomainInfo.description) && helpers.nullOrEmpty(description))) {
          errors.push({
            section: 'general',
            field: 'description',
            text: 'La descripción del dominio no puede estar vacía ni ser inferior a 3 caractéres'
          })
        }
        if ((callback_url != null && callback_url.length < 3) || (helpers.nullOrEmpty(this.commstrackerDomainInfo.callback_url) && helpers.nullOrEmpty(callback_url))) {
          errors.push({
            section: 'general',
            field: 'callback_url',
            text: 'La url de callback no puede estar vacía ni ser inferior a 3 caractéres'
          })
        }
        if ((default_redirect != null && default_redirect.length < 3) || (helpers.nullOrEmpty(this.commstrackerDomainInfo.default_redirect) && helpers.nullOrEmpty(default_redirect))) {
          errors.push({
            section: 'general',
            field: 'default_redirect',
            text: 'La url de redirección por defecto no puede estar vacía ni ser inferior a 3 caractéres'
          })
        }
      }
      if (!this.isEditing && this.commstrackerDomainInfo.blocked) {
        errors.push({
          section: 'general',
          field: 'blocked',
          text: 'Cuidado, dominio bloqueado. No debe usarse en ningún proceso activo'
        })
      }
      return errors
    }
  },
  watch: {
    commstrackerDomain(value) {
      Object.assign(this.commstrackerDomainInfo, value)
    }
  },
  created() {
    if (this.commstrackerDomain == 'new' || this.commstrackerDomain == null) {
      this.newDomain = true
      this.isEditing = true
      this.commstrackerDomainInfo = {
        domain: '',
        description: '',
        blocked: 0,
        default_redirect: '',
        callback_url: ''
      }
    } else {
      this.commstrackerDomainInfo = Object.assign({}, this.commstrackerDomain)
      this.isEditing = this.edit
      this.domainProperties.push(
        {
          id: 'delete',
        })
    }
  },
  methods: {
    changeProp(data) {
      if (helpers.nullOrEmpty(this.changesPending)) {
        this.changesPending = {}
      }
      Object.keys(data).forEach(prop_key => {

        if (this.commstrackerDomainInfo[prop_key] != data[prop_key]) {
          this.$set(this.changesPending, prop_key, data[prop_key])
        } else if (!helpers.nullOrEmpty(this.changesPending) && this.changesPending[prop_key] != null) {
          this.$delete(this.changesPending, prop_key)
        }
      })
    },
    async deleteDomain() {
      this.confirmSave = true
      if (
        await this.$refs.confirm.open(
          'Confirmar borrado',
          '¿Confirmar que deseas BORRAR el dominio ' + this.commstrackerDomainInfo.domain + 'de Commstracker?',
          {password: true}
        )
      ) {
        service_protocol.deleteCommstrackerDomain(this.commstrackerDomainInfo.domain).then(() => {
          this.$emit('close')
        })
      }
    },
    discard() {

      this.isEditing = false
      this.changesPending = {}
      this.$emit('close')
    },
    async save() {
      this.confirmSave = true
      if (
        await this.$refs.confirm.open(
          'Confirmar cambios',
          '¿Deseas confirmar los cambios en el dominio de Commstracker?',
          {password: false}
        )
      ) {
        this.isEditing = false
        if (this.newDomain) {
          if (this.changesPending.blocked == null) {
            this.changesPending.blocked = 0
          }
        }
        let code = this.commstrackerDomain ? this.commstrackerDomain.domain : null
        service_protocol.createOrUpdateCommstrackerDomain(code, this.changesPending).then(() => {
          this.changesPending = {}
          if (this.newDomain) {
            this.$emit('close')
          } else {
            this.$emit('refresh')

          }
        })
      }
    },
    composeArrayPerm(permissions) {
      permissions.filter(p => p.assigned).forEach(root_perm => {
        this.permsToChange.push(root_perm.key)
        if (root_perm.children.length != 0) {
          this.composeArrayPerm(root_perm.children)
        }
      })
    },
  }
}
</script>


