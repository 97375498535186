<template>
  <v-dialog
    v-model="open"
    max-width="900px"
    persistent
    transition="dialog-bottom-transition"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <v-card
      v-if="userInfo != null"
      class="cb-edit-dialog pa-0"
    >
      <card-title
        :close="true"
        :icon="$icons.I_USER"
        title="Detalles de usuario"
        @close="$emit('close')"
      />
      <v-card-text
        class="pa-0"
      >
        <v-row>
          <v-col class="px-4">
            <RowPropertyConfig
              v-for="prop in userProperties"
              :key="prop.id"
              :error="errors.find(e => e.field == prop.id)"
              :is-editing="isEditing"
              :property="prop"
              :value="userInfo[prop.id]"
              @change="changeProp"
            />
          </v-col>
        </v-row>

        <v-row class="px-5 contrast_low" dense>
          <v-col class="py-2">
            <v-row class="mb-2" dense>
              <h3>Acciones disponibles</h3>
            </v-row>
            <v-row class="pb-2" dense>
              <button-with-icon-model-a
                :color="user.active ? 'warning':'success'"
                :disabled="isEditing"
                :icon="user.active ? 'toggle_off':'published'"
                :text="user.active ? 'Desactivar usuario':'Activar usuario'"
                @click="changeUserStatus()"
              />
              <button-with-icon-model-a
                :disabled="isEditing"
                class="mx-2"
                icon="email"
                text="Email cambio contraseña"
                title="Enviar mail cambio de contraseña"
                @click="changeUserPasswordEmail()"
              />
              <button-with-icon-model-a
                :disabled="isEditing"
                color="error"
                icon="delete"
                text="Borrar usuario"
                @click="deleteUser()"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <dialog-card-footer
        :close-button-visible="true"
        :delete-button-visible="true"
        :edit-button-visible="!isEditing && service_user.checkGroupPermission(P_MANAGEMENT_DP_EDIT)"
        :save-button-disabled="!paramsValid"
        :save-button-visible="isEditing"
        delete-button-custom-text="Borrar usuario"
        @close="$emit('close')"
        @delete="actionLaunched('delete')"
        @edit="actionLaunched('edit')"
        @save="actionLaunched('save')"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import RowPropertyConfig from '@/components/repository/RowPropertyConfig'
import types from '@/constants/types'
import helpers from '@/plugins/helpers'
import CardTitle from '@/components/repository/cards/CardTitle'
import service_user from '@/services/service_user'
import {P_MANAGEMENT_DP_EDIT} from '@/constants/group_permissions'
import DialogCardFooter from '@/components/repository/dialogs/DialogCardFooter.vue'
import service_devPortal from "@/services/service_devPortal";
import ButtonWithIconModelA from "@/components/repository/buttons/ButtonWithIconModelA.vue";

export default {
  name: 'DevPortalCreateOrUpdateUser',
  components: {
    ButtonWithIconModelA,
    DialogCardFooter,
    CardTitle,
    RowPropertyConfig
  },
  props: {
    user: {
      type: [String, Object],
      default: null
    }
  },
  data() {
    return {
      service_user,
      helpers,
      P_MANAGEMENT_DP_EDIT,
      userInfo: null,
      open: true,
      isEditing: false,
      step: 0,
      changesPending: {},
      userProperties: [
        {
          id: 'username',
          name: 'Email',
          disabled: this.user != 'new',
          description: 'Email que va a tener el usuario en la plataforma',
          type: types.STRING,
          extra: {
            maxLength: 255
          }
        },
        {
          id: 'description',
          name: 'Descripción',
          description: 'Descripción del usuario',
          type: types.STRING,
          extra: {
            maxLength: 255
          }
        },
        {
          id: 'group_id',
          name: 'Grupo',
          description: 'Grupo al que pertenece el usuario',
          type: types.FINDER,
          alias: 'name',
          extra: {
            not_null: true,
            finder_url: 'app-center/developer-site/groups',
            item_text: 'name',
            item_value: 'id'
          }
        },
        {
          id: 'expiration',
          name: 'Expiración',
          description: 'Expiración de la cuenta de usuario',
          type: types.TIMESTAMP,
          extra: {}
        },
      ]
    }
  },
  computed: {
    errors() {
      let errors = []
      if (this.changesPending != null && Object.keys(this.changesPending).length > 0) {

        if ((this.changesPending['username'] != null && (this.changesPending['username'].length < 3 || this.changesPending['username'].indexOf('@') == -1 || this.changesPending['username'].indexOf('.') == -1)) ||
          this.userInfo['username'] == null) {
          errors.push({
            section: 'general',
            field: 'username',
            text: 'El email no puede estar vacío, debe ser un email y no puede ser inferior a 3 caractéres'
          })
        }
        if ((this.changesPending['description'] != null && this.changesPending['description'].length < 3) ||
          (this.changesPending['description'] == null && this.userInfo['description'] == '')) {
          errors.push({
            section: 'general',
            field: 'description',
            text: 'La descripción del usuario no puede estar vacía ni ser inferior a 3 caractéres'
          })
        }
        if (this.changesPending['group_id'] == null && this.userInfo['group_id'] == null) {
          errors.push({
            section: 'general',
            field: 'group_id',
            text: 'El usuario debe pertenecer a un grupo'
          })
        }
        if (this.changesPending['expiration'] != null && this.changesPending['expiration'] == null && this.userInfo['expiration'] != null) {
          errors.push({
            section: 'general',
            field: 'expiration',
            text: 'La expiración del usuario no puede estar vacía ni ser inferior a 3 caractéres'
          })
        }
      }
      return errors
    },
    paramsValid() {
      return this.errors.length == 0 && this.changesPending != null && Object.keys(this.changesPending) != 0
    },
    newUserInfo() {
      return this.userInfo == 'new'
    },
  },
  created() {
    if (this.user != 'new') {
      this.userInfo = Object.assign({}, this.user)
      this.userInfo.expiration = new Date(this.user.expiration * 1000).toISOString().substring(0, 16)
    } else {
      this.isEditing = true

      this.userInfo = {
        ['username']: '',
        ['description']: ''
      }
    }
  },
  methods: {

    changeProp(data) {
      if (helpers.nullOrEmpty(this.changesPending)) {
        this.changesPending = {}
      }
      Object.keys(data).forEach(prop_key => {

        if (this.userInfo[prop_key] != data[prop_key]) {
          this.$set(this.changesPending, prop_key, data[prop_key])
        } else if (!helpers.nullOrEmpty(this.changesPending) && this.changesPending[prop_key] != null) {
          this.$delete(this.changesPending, prop_key)
        }
      })
    },
    actionLaunched(action) {
      if (action == 'edit') {
        this.isEditing = true
      }
      if (action == 'discard') {
        if (confirm('¿Deseas descartar los cambios?')) {
          this.discard()
        }
      }
      if (action == 'delete') {
        if (confirm('¿Deseas BORRAR el usuario?')) {
          service_devPortal.deleteUser(this.userInfo.id)
        }
      }
      if (action == 'save') {
        if (confirm('¿Deseas guardar los cambios?')) {
          this.save()
        }
      }
    },
    changeUserStatus() {
      if (confirm('¿Confirmas que deseas desactivar el usuario?')) {

        this.changesPending = {
          active: this.user.active ? 0 : 1
        }
        this.save()
      }
    },
    deleteUser() {
      if (confirm('¿Deseas BORRAR el usuario?')) {
        service_devPortal.deleteUser(this.userInfo.id)
      }
    },
    changeUserPasswordEmail() {
      if (confirm('Se procederá a enviar el email para establecer una nueva contraseña al usuario. ¿Estás seguro?')) {
        service_devPortal.changePasswordEmail(this.user.id)
      }
    },
    discard() {

      this.isEditing = false
      this.changesPending = {}
      this.$emit('close')
    },
    save() {
      this.isEditing = false
      let code = this.user ? this.user.id : null
      if (this.changesPending['expiration']) {
        this.changesPending['expiration'] = Math.floor(new Date(this.changesPending['expiration']).getTime() / 1000);
      }
      service_devPortal.createOrUpdateUserInfo(code, this.changesPending).then(() => {
        this.changesPending = {}
        this.$emit('close')
      })

    },
    composeArrayPerm(permissions) {
      permissions.filter(p => p.assigned == true).forEach(root_perm => {
        this.permsToChange.push(root_perm.key)
        if (root_perm.children.length != 0) {
          this.composeArrayPerm(root_perm.children)
        }
      })
    },
  }
}
</script>


