<template>
  <v-container>
    <v-row>
      <v-col class="pr-4" cols="4">
        <title-model-a icon="search" title="Consulta el estado de los dominios"/>
        <v-row v-if="checkTypeDomains" class="my-4 px-2">
          <v-text-field
            id="domain-key" v-model="domain_name" clearable
            hide-details
            outlined placeholder="Dominio a consultar"
            type="text"></v-text-field>
        </v-row>
        <v-row v-else class="my-4 px-2">

          <FinderSelector
            v-model="domain_id"
            :hide_value="true"
            icon="email"
            placeholder="Selecciona un dominio"
            text_name="name"
            text_value="id"
            url="app-center/commservices/email-accounts"
          />

        </v-row>
        <v-row class="px-2" justify="space-between">
          <v-col class="pa-0" cols="4">
            <v-select
              v-model="checkTypeDomains"
              :items="[
              {text: 'Dominio', value: true},
              {text: 'Selector de cuenta', value: false}
            ]"
              dense
              hide-details
              outlined></v-select>
          </v-col>
          <button-icon-text-model-b
            :disabled=" (!checkTypeDomains && (helpers.nullOrEmpty(domain_id))) || (checkTypeDomains && helpers.nullOrEmpty(domain_name))"
            icon="search"
            text="Consultar"
            @click="checkDomain()">

          </button-icon-text-model-b>
        </v-row>
        <v-row v-if="lastTenRequestsFromLocalStorage && lastTenRequestsFromLocalStorage.length != 0">
          <v-col>
            <v-divider class="my-4"/>
            <title-model-a icon="history" title="Últimas 10 consultas en caché"/>
            <v-list dense>
              <v-list-item v-for="(request,index) in lastTenRequestsFromLocalStorage" :key="index"
                           class="mt-2 cb-row-bordered" dense
                           two-line>
                <v-list-item-content>
                  <v-list-item-title>{{request.date | moment('DD MM YYYY')}} ({{request.date | moment('from')}})
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span class="text-uppercase">{{request.result.map(d => d.name).join(', ')}}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-row>
                    <v-btn icon @click="reloadRequestFromLocalStorage(index)">
                      <v-icon color="primary">visibility</v-icon>
                    </v-btn>
                    <v-btn icon @click="deleteRequest(index)">
                      <v-icon color="error">delete</v-icon>
                    </v-btn>

                  </v-row>
                </v-list-item-action>
              </v-list-item>

            </v-list>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="result" cols="8">
        <title-model-a :title="'Dominios asociados  '+result.length " icon="info"/>
        <v-expansion-panels v-model="domainOpen" class="mt-2" multiple>
          <v-expansion-panel v-for="(domain, index) in result" :key="index" class="mb-2">
            <v-expansion-panel-header>
              <template v-slot:default>
                <v-list-item two-line>

                  <v-list-item-content class="py-0">
                    <v-list-item-title class="cb-font-size-subtitle pb-2">
                      {{domain.name}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span v-for="(variable, index) in booleanVariables" :key="index"
                      >
                        <v-icon :color=" domain[variable.key] ? 'success':'error'" class="mr-1"
                                small> {{domain[variable.key] ? 'check' : 'warning'}}</v-icon>
                        <span :class="domain[variable.key] ? 'success--text':'error--text'" class="font-weight-medium"
                              style="vertical-align: middle;">{{variable.text}}</span>
                        <span v-if="index !== booleanVariables.length-1" class="mx-2 cb-font-size-2 grey--text"
                              style="vertical-align: text-bottom;">|</span>
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table class="my-4" dense>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left" style="max-width: 30%;width: 30%">Clave</th>
                    <th class="text-left">Valor</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(value, key) in domain" :key="key">
                    <td style="max-width: 30%;width: 30%">{{key}}</td>
                    <td>
                      <span v-if="typeof value === 'boolean'" :class="value ? 'success--text':'error--text'"
                            class="font-weight-bold align_vertical_center">
                        <v-icon v-if="!value" class="mr-1" color="error" small>warning</v-icon>
                        {{value ? 'OK' : 'FAIL'}}
                      </span>
                      <v-col v-else-if="key === 'returnPathInfo'" class="pa-0">
                        <v-jsoneditor
                          :options="{mode:'code',mainMenuBar : false,statusBar : false}"
                          :value="JSON.parse(value)"
                          height="400px"
                        />
                      </v-col>
                      <span v-else style="word-break: break-all;">{{value}}</span>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import {defineComponent} from 'vue'
import ButtonIconTextModelB from "@/components/repository/buttons/ButtonIconTextModelB.vue";
import TitleModelA from "@/components/repository/TitleModelA.vue";
import helpers from "@/plugins/helpers";
import service_commservices from "@/services/service_commservices";
import FinderSelector from "@/components/repository/fields/FinderSelector.vue";

export default defineComponent({
  name: "domainsChecker",
  components: {FinderSelector, TitleModelA, ButtonIconTextModelB},
  data: () => ({
    helpers,
    domain_name: '',
    checkTypeDomains: false,
    result: null,
    domainOpen: [],
    lastTenRequestsFromLocalStorage: JSON.parse(localStorage.getItem('lastTenRequests')) || [],
    domain_id: null,
    booleanVariables: [
      {key: 'DKIMIs2048', text: 'Clave 2048'},
      {key: 'DKIMStatus', text: 'DKIM'},
      {key: 'SPFStatus', text: 'SPF'},
      {key: 'DMARCStatus', text: 'DMARC'},
      {key: 'googlePostmasterStatus', text: 'Google Postmaster'},
      {key: 'delegationStatus', text: 'Delegación'},
      {key: 'returnPathStatus', text: 'Return Path'},

    ],
  }),
  watch: {
    checkTypeDomains() {
      this.domain_id = null
      this.domain_name = ''
    }
  },
  methods: {
    async checkDomain() {
      //trim before send
      this.domain_name = this.domain_name.trim()
      this.result = null
      const result = await service_commservices.checkDomain(this.domain_id, this.domain_name === '' ? null : this.domain_name)
      if (result.data.message === "domains_info") {
        this.result = result.data.extra
        const newLastRequests = [{
          date: this.$moment().unix(),
          result: result.data.extra
        }, ...this.lastTenRequestsFromLocalStorage]
        if (newLastRequests.length >= 10) {
          newLastRequests.pop()
        }
        localStorage.setItem('lastTenRequests', JSON.stringify(newLastRequests))
        this.lastTenRequestsFromLocalStorage = newLastRequests
      }
    },
    reloadRequestFromLocalStorage(index) {
      this.result = this.lastTenRequestsFromLocalStorage[index].result
    },
    deleteRequest(index) {
      this.lastTenRequestsFromLocalStorage.splice(index, 1)
      localStorage.setItem('lastTenRequests', JSON.stringify(this.lastTenRequestsFromLocalStorage))
    }

  }
})
</script>

<style lang="scss" scoped>

</style>