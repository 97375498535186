<template>
  <v-container>
    <ExplorerFiltersRow
      :actions="actions"
      :model="modelName"
      :response-filters="responseFilters"
      :search="true"
      @[$events.EXPLORER_REMOVE_FILTER]="removeFilter"
      @[$events.EXPLORER_SEARCH]="searchByText"
      @[$events.EXPLORER_ACTION_CLICKED]="actionClicked"
    />
    <v-row>
      <DataTable
        :custom-row="false"
        :data="dataTableData"
        :data-table-options="dataTableOptions"
        :headers="dataTableHeaders"
        :loading="loading"
        :model="modelName"
        @[$events.DATA_TABLE_REFRESH]="find"
        @[$events.DATA_TABLE_OPEN]="openItem"
      >
      </DataTable>
    </v-row>
    <ExportDataTable
      v-if="showExportDialog"
      :fields="dataTableHeaders"
      :model="modelName"
      :response_filters="responseFilters"
      :service="service_devPortal.getGroups"
      @close="showExportDialog=false"
    />
    <DevPortalCreateOrUpdateGroup
      v-if="itemSelected != null"
      :fields="dataTableHeaders"
      :group="itemSelected"
      :response_filters="responseFilters"
      @close="closeItem()"
      @refresh="find()"
    />
  </v-container>
</template>

<script>
import helpers_resolution from '@/plugins/helpers_resolution'
import service_user from '@/services/service_user'
import ExplorerFiltersRow from '@/components/repository/explorers/ExplorerFiltersRow'
import DataTable from '@/components/repository/tables/DataTable'
import {
  P_EXPORT, P_MANAGEMENT_DP_EDIT
} from '@/constants/group_permissions'
import ExportDataTable from '@/components/repository/tables/dataTable/ExportDataTable'
import {datatableMixin} from '@/mixins/dataTable'
import icons from '@/constants/icons'
import service_devPortal from "@/services/service_devPortal";
import DevPortalCreateOrUpdateGroup
  from "@/components/administration/developerPortal/components/DevPortalCreateOrUpdateGroup.vue";

const modelName = 'Grupos de integración'
const dataTableConfig = {
  options: {
    rpp: helpers_resolution.getTableRowsPerPage(),
    sortDesc: [false],
    page: 1,
    sortBy: ['name']
  },
  finder: service_devPortal.getGroups
}

export default {
  name: 'groupsDeveloperPortal',
  components: {
    DevPortalCreateOrUpdateGroup,
    ExportDataTable,
    DataTable,
    ExplorerFiltersRow,
  },
  mixins: [datatableMixin(dataTableConfig)],
  data() {
    return {
      service_devPortal,
      modelName,
      dataTableHeaders: [
        {
          text: 'ID',
          value: 'id',
          hide: true,
          align: ' d-none'
        },
        {
          text: 'Nombre',
          value: 'name',
          sortable: true,
          type: 'link',
          width: '20%'
        },
        {
          text: 'Descripción',
          value: 'description',
          sortable: true,
          type: 'text',
          width: '60%'
        },
        {
          text: 'Procesos asociados',
          value: 'processes',
          sortable: true,
          type: 'text',
          width: '20%'
        },
        {
          text: '',
          value: 'actions',
          align: 'center',
          sortable: false,
          custom: true,
          width: '40px',
          actions: () => {

            let entities = []

            if (service_user.checkGroupPermission(P_MANAGEMENT_DP_EDIT)) {

              entities.push({
                id: 'deleteGroup',
                icon: icons.I_DELETE,
                title: 'Borrar grupo',
                enabled: true,
              })
            }
            return entities

          }
        }],
    }
  },
  computed: {
    actions() {
      let entities = []
      if (service_user.checkGroupPermission(P_EXPORT)) {
        entities.push({
          id: 'export',
          icon: this.$icons.I_EXPORT,
          text: 'Exportar datos',
          enabled: true,
        })
      }
      if (service_user.checkGroupPermission(P_MANAGEMENT_DP_EDIT)) {
        entities.push({
          id: 'new',
          icon: this.$icons.I_GROUP,
          text: 'Nuevo grupo',
          enabled: true,
        })
      }
      return entities
    }
  },
  methods: {
    actionClicked(action_id) {
      if (action_id === 'export') {
        this.showExportDialog = true
      }
      if (action_id === 'new') {
        this.openItem('new')
      }
    }
  },
  filters: {}
}
</script>

<style lang="scss">
@import "../../../assets/styles/resources/colors";
@import "../../../assets/styles/resources/variables";

</style>
