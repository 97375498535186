<template>
  <v-dialog
    v-model="open"
    max-width="900px"
    persistent
    transition="dialog-bottom-transition"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <v-card
      v-if="groupInfo != null"
      class="cb-edit-dialog pa-0"
    >
      <card-title
        :close="true"
        :icon="$icons.I_GROUP"
        title="Detalles del grupo"
        @close="$emit('close')"
      />
      <v-card-text
        class="pa-0"
      >
        <v-row>
          <v-col class="px-4">
            <RowPropertyConfig
              v-for="prop in groupProperties"
              :key="prop.id"
              :error="errors.find(e => e.field == prop.id)"
              :is-editing="isEditing"
              :property="prop"
              :value="groupInfo[prop.id]"
              @change="changeProp"
            />
          </v-col>
        </v-row>

        <v-row class="px-5 py-2 contrast_low" dense>
          <v-col cols="12">
            <v-row class="mb-2">
              <span class="my-auto text-uppercase font-weight-medium"> Procesos asociados </span>
            </v-row>
            <v-row v-if="isEditing">

              <v-col>
                <FinderSelector
                  v-model="processToAdd"
                  :return_object="true"
                  :text_name="model_process.NAME"
                  :text_value="model_process.CODE"
                  placeholder="Proceso a añadir"
                  url="app-center/processes"
                />
              </v-col>
              <v-col>
                <button-with-icon-model-a
                  :disabled="processToAdd == null"
                  color="success"
                  icon="add"
                  text="Añadir proceso"
                  @click="addProcess"
                />

              </v-col>
            </v-row>
            <v-row
              v-for="process in groupProcesses"
              :key="process.code">
              <v-chip
                class="ma-1"
                color="primary"
                label
                small
              >
                {{process.process_name}}
                <v-icon
                  v-if="isEditing"
                  class="ml-1"
                  color="white"
                  small
                  @click="removeProcess(process.process_code)"
                >
                  clear
                </v-icon>
              </v-chip>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <dialog-card-footer
        :close-button-visible="true"
        :delete-button-visible="true"
        :edit-button-visible="!isEditing && service_user.checkGroupPermission(P_MANAGEMENT_DP_EDIT)"
        :save-button-disabled="!paramsValid"
        :save-button-visible="isEditing"
        delete-button-custom-text="Borrar usuario"
        @close="$emit('close')"
        @delete="actionLaunched('delete')"
        @edit="actionLaunched('edit')"
        @save="actionLaunched('save')"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import RowPropertyConfig from '@/components/repository/RowPropertyConfig'
import types from '@/constants/types'
import helpers from '@/plugins/helpers'
import CardTitle from '@/components/repository/cards/CardTitle'
import service_user from '@/services/service_user'
import {P_MANAGEMENT_DP_EDIT} from '@/constants/group_permissions'
import DialogCardFooter from '@/components/repository/dialogs/DialogCardFooter.vue'
import service_devPortal from "@/services/service_devPortal";
import ButtonWithIconModelA from "@/components/repository/buttons/ButtonWithIconModelA.vue";
import FinderSelector from "@/components/repository/fields/FinderSelector.vue";
import model_process from "@/constants/models/model_process";

export default {
  name: 'DevPortalCreateOrUpdateGroup',
  components: {
    FinderSelector,
    ButtonWithIconModelA,
    DialogCardFooter,
    CardTitle,
    RowPropertyConfig
  },
  props: {
    group: {
      type: [String, Object],
      default: null
    }
  },
  data() {
    return {
      service_user,
      helpers,
      P_MANAGEMENT_DP_EDIT,
      groupInfo: null,
      open: true,
      isEditing: false,
      step: 0,
      changesPending: {},
      groupProcesses: [],
      processToAdd: null,
      groupProperties: [
        {
          id: 'name',
          name: 'Nombre',
          disabled: this.group != 'new',
          description: 'Nombre del grupo',
          type: types.STRING,
          extra: {
            maxLength: 255
          }
        },
        {
          id: 'description',
          name: 'Descripción',
          description: 'Descripción del grupo',
          type: types.STRING,
          extra: {
            maxLength: 255
          }
        },
      ]
    }
  },
  computed: {
    model_process() {
      return model_process
    },
    errors() {
      let errors = []
      if (this.changesPending != null && Object.keys(this.changesPending).length > 0) {

        if ((this.changesPending['name'] != null && this.changesPending['name'].length < 3) ||
          (this.changesPending['name'] == null && this.groupInfo['name'] == '')) {
          errors.push({
            section: 'general',
            field: 'name',
            text: 'El email no puede estar vacío, debe ser un email y no puede ser inferior a 3 caractéres'
          })
        }
        if ((this.changesPending['description'] != null && this.changesPending['description'].length < 3) ||
          (this.changesPending['description'] == null && this.groupInfo['description'] == '')) {
          errors.push({
            section: 'general',
            field: 'description',
            text: 'La descripción del usuario no puede estar vacía ni ser inferior a 3 caractéres'
          })
        }
      }
      return errors
    },
    paramsValid() {
      return this.errors.length == 0 && this.changesPending != null && Object.keys(this.changesPending) != 0
    },
    newgroupInfo() {
      return this.groupInfo == 'new'
    },
  },
  created() {

    if (this.group != 'new') {
      this.groupInfo = Object.assign({}, this.group)
      this.getProcesses()
    } else {
      this.isEditing = true

      this.groupInfo = {
        ['name']: '',
        ['description']: '',
        ['processes']: []
      }
    }
  },
  methods: {
    getProcesses() {
      service_devPortal.getGroupProcesses(this.groupInfo.id).then(response => {
        this.groupProcesses = response.data.extra.results
      })
    },
    changeProp(data) {
      if (helpers.nullOrEmpty(this.changesPending)) {
        this.changesPending = {}
      }
      Object.keys(data).forEach(prop_key => {

        if (this.groupInfo[prop_key] != data[prop_key]) {
          this.$set(this.changesPending, prop_key, data[prop_key])
        } else if (!helpers.nullOrEmpty(this.changesPending) && this.changesPending[prop_key] != null) {
          this.$delete(this.changesPending, prop_key)
        }
      })
    },
    actionLaunched(action) {
      if (action == 'edit') {
        this.isEditing = true
      }
      if (action == 'discard') {
        if (confirm('¿Deseas descartar los cambios?')) {
          this.discard()
        }
      }
      if (action == 'delete') {
        if (confirm('¿Deseas BORRAR el grupo?')) {
          service_devPortal.deleteGroup(this.groupInfo.id)
        }
      }
      if (action == 'save') {
        if (confirm('¿Deseas guardar los cambios?')) {
          this.save()
        }
      }
    },
    discard() {

      this.isEditing = false
      this.changesPending = {}
      this.$emit('close')
    },
    save() {
      this.isEditing = false
      let code = this.group ? this.group.id : null
      if (service_user.checkGroupPermission(P_MANAGEMENT_DP_EDIT)) {
        service_devPortal.createOrUpdateGroupInfo(code, this.changesPending).then(() => {
          this.changesPending = {}
          this.$emit('close')
        })
      }

    },
    composeArrayPerm(permissions) {
      permissions.filter(p => p.assigned == true).forEach(root_perm => {
        this.permsToChange.push(root_perm.key)
        if (root_perm.children.length != 0) {
          this.composeArrayPerm(root_perm.children)
        }
      })
    },
    removeProcess(process) {
      this.groupProcesses = this.groupProcesses.filter(p => p.process_code != process)
      this.$set(this.changesPending, 'processes', this.groupProcesses.map(p => p.process_code))
    },
    addProcess() {
      this.groupProcesses.push({
        process_code: this.processToAdd.code,
        process_name: this.processToAdd.name
      })
      this.$set(this.changesPending, 'processes', this.groupProcesses.map(p => p.process_code))
      this.processToAdd = null
    }
  }
}
</script>


