import {ADMIN} from '@/constants/categories'
import {P_ADMINISTRATION} from '@/constants/group_permissions'

const GroupsAppCenter = () => import(/* webpackChunkName: "Administration"*/ '@/views/administration/GroupsAppCenter')
const UsersAppCenter = () => import(/* webpackChunkName: "Administration"*/ '@/views/administration/UsersAppCenter')

const ROOT = '/administration/'
const CATEGORY = ADMIN
export default [
  {
    path: ROOT + 'groups',
    name: 'admin_groups',
    component: GroupsAppCenter,
    meta: {
      perm: P_ADMINISTRATION,
      title: 'Gestión de grupos de APP Center',
      title_short: 'Grupos',
      navigable: true,
      category: CATEGORY,
      requiresAuth: true
    },
  },
  {
    path: ROOT + 'users',
    name: 'admin_users',
    component: UsersAppCenter,
    meta: {
      perm: P_ADMINISTRATION,
      title: 'Gestión de usuarios de APP Center',
      title_short: 'Usuarios',
      navigable: true,
      category: CATEGORY,
      icon: 'person',
      requiresAuth: true
    },
  }
]
