import Axios from "axios";
import {objectToFormData} from "object-to-formdata";
import service_explorers from "@/services/global/service_explorers";

export default {
  createUser(username, description, group_id, expiration) {
    return Axios.post(process.env.VUE_APP_ENDPOINT + 'app-center/developer-site/user', objectToFormData({
      username,
      description,
      group_id,
      expiration
    }), {indices: true})
  },
  createOrUpdateUserInfo(id, properties) {
    let params = {}
    Object.keys(properties).forEach((key_prop) => {
      if (typeof properties[key_prop] == 'undefined') {
        properties[key_prop] = null
      }
      params[key_prop] = properties[key_prop]
    })
    let form_data_object = objectToFormData(params, {indices: true})
    let url = id == null ? 'app-center/developer-site/users' : 'app-center/developer-site/users/' + id
    return Axios.post(
      process.env.VUE_APP_ENDPOINT + url,
      form_data_object
    )
  },
  deleteUser(id) {
    return Axios.delete(process.env.VUE_APP_ENDPOINT + 'app-center/developer-site/users/' + id)
  },
  getUsers(fields, filters, options) {
    return service_explorers.find('app-center/developer-site/users', fields, filters, options)
  },
  changePasswordEmail(id) {
    return Axios.post(
      process.env.VUE_APP_ENDPOINT + 'app-center/developer-site/users/' + id + '/change-password'
    )
  },
  getGroups(fields, filters, options) {
    return service_explorers.find('app-center/developer-site/groups', fields, filters, options)
  },
  createGroup(name, description) {
    return Axios.post(process.env.VUE_APP_ENDPOINT + 'app-center/developer-site/groups', objectToFormData({
      name,
      description
    }), {indices: true})
  },
  deleteGroup(id) {
    return Axios.delete(process.env.VUE_APP_ENDPOINT + 'app-center/developer-site/groups/' + id)
  },
  getGroupProcesses(id) {
    return Axios.get(process.env.VUE_APP_ENDPOINT + 'app-center/developer-site/groups/' + id + '/processes')
  },

  createOrUpdateGroupInfo(id, properties) {
    let params = {}
    Object.keys(properties).forEach((key_prop) => {
      if (typeof properties[key_prop] == 'undefined') {
        properties[key_prop] = null
      }
      params[key_prop] = properties[key_prop]
    })
    let form_data_object = objectToFormData(params, {indices: true})
    let url = id == null ? 'app-center/developer-site/groups' : 'app-center/developer-site/groups/' + id
    return Axios.post(
      process.env.VUE_APP_ENDPOINT + url,
      form_data_object
    )
  },
}
