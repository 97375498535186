import icons from '@/constants/icons'

let constants = {
  ENTITY_NAME: 'process',
  NAME: 'name',
  CODE: 'code',

  /* Process Fields*/
  MASTER_ACCOUNT_CODE: 'master_account_code',
  MASTER_ACCOUNT_NAME: 'master_account_name',

  SUB_ACCOUNT_CODE: 'subaccount_code',
  SUB_ACCOUNT_NAME: 'subaccount_name',

  PROJECT_CODE: 'project_code',
  PROJECT_NAME: 'project_name',

  POSTAMAIL_LOGIN: 'postamail_login',

  TEMPLATE_CODE: 'workflow_template_code',
  DEFAULT_LANGUAGE: 'default_language',
  APPEARANCE_CODE: 'appearance_code',
  PROJECT: 'project',
  CERT_ENTITY_NAME: 'certification_entity_name',
  CERT_EXPIRATION_DAYS: 'certificates_expiration_days',
  ALASTRIA: 'alastria',
  COMMUNICALIA_SYNC: 'communicalia_sync',
  STOPPED: 'stopped',
  CERTIFICATE_LANGUAGE: 'certificate_language',
  CERTIFICATE_ISSUER: 'certificate_issuer',
  FORMAT_PDF_CERTIFICATES: 'pdf_format_certificates',
  FORMAT_PDF_EVENTS: 'pdf_format_ts_events',
  CECO_REQUIRED: 'requires_cost_center',
  DEFAULT_CECO: 'default_billing_unit',
  DEFAULT_ANAL_UNIT: 'default_analytical_unit',
  OF: 'of',
  REQUIRES_COST_CENTER: 'requires_cost_center',
  POSTAL_ENVELOPE: 'postal_envelope',
  B2B: 'b2b',
  VARIABLES: 'variables',
  IS_B2B: 'is_b2b',
  FILES_TO_DELETE: 'files_to_delete',
  NEW_FILES: 'new_files',
  EMAIL_ENTITY: 'email_entity',
  EMAIL_STREAM_TYPE: 'email_stream_type',
  EMAIL_LOGIN: 'email_login',
  EMAIL_PASSWORD: 'email_pwd',
  SMS_PROVIDER: 'sms_provider',
  COMMSTRACKER_SMS: 'commstracker_domain_sms',
  COMMSTRACKER_EMAIL: 'commstracker_domain_email',
  API_INTROMISSION: 'intromission_api_bitmask',
  API_ENQUIRY: 'enquiry_api_bitmask',
  AUTHENTICATION_METHOD: 'auth_method',
  AUTHENTICATION_USER: 'auth_username',
  AUTHENTICATION_PASSWORD: 'auth_password',
  AUTHENTICATION_SERIAL: 'auth_certificate_serial',
  CERTIFICATION_ENTITY_NAME: 'certification_entity_name',
  QUALIFIED_TEXTS: 'qualified_texts',
  SEAL_PROVIDER: 'seal_provider',
  TSA_PROVIDER: 'tsa_provider',
  CERTIFICATE_PROVIDER: 'certificate_provider',
  MACROSTATUS_CALLBACKS: 'macrostatus_callbacks',
  CUSTOM_FW_DOMAIN: 'custom_fw_domain',
  CUSTOM_FR_DOMAIN: 'custom_fr_domain',

  CERTIFICATE_ISSUER_NAME: 'certificate_issuer_name',
  CERTIFICATE_ISSUER_CODE: 'certificate_issuer_code',

  WHATSAPP_SENDER: 'whatsapp_sender',
  WHATSAPP_CLIENT_ID: 'whatsapp_client_id',
  WHATSAPP_CLIENT_SECRET: 'whatsapp_client_secret',
  WHATSAPP_API_ID: 'whatsapp_api_id',

  B2B_IMAGE: 'image',
  B2B_TAGS: 'tags',
  B2B_BEHAVIOR_LAYER: 'behavior_layer',
  B2B_TRANSLATION_ID: 'translation_process',
  B2B_TRANSLATION_CATEGORY: 'translation_category',

  CLIENT_ID: 'client_id',
  NAVISION_ID: 'navision_id',
  SEG01: 'seg01',
  SEG02: 'seg02',
  SEG03: 'seg03',
  SEG04: 'seg04',
  SEG05: 'seg05',
  SEG06: 'seg06',
  COMPONENTS_MAPPING: 'components_mapping',


  API_INTROMISSION_PERMISSIONS: {
    RETRIES_MANIPULATING: {
      id: 'retries_manipulating',
      value: 1,
      description:
        'Manipulación de reintentos de la instancia'
    },
    TRANSACTION_CANCELLATION: {
      id: 'transaction_cancellation',
      value: 2,
      description:
        'Cancelación de transacción'
    },
    ADD_EXTERNAL_DOCUMENT: {
      id: 'add_external_document',
      value: 4,
      description:
        'Añadir documentos externos a la instancia'
    },
    ADD_EXTERNAL_CERTIFICATE: {
      id: 'add_external_certificate',
      value: 8,
      description:
        'Añadir certificados externos a la instancia'
    },
    ADD_FRAMEWORK_SIGNALS: {
      id: 'add_framework_signals',
      value: 32,
      description:
        'Añadir señales al framework web'
    },
    CHECK_VARIABLES: {
      id: 'check_variables',
      value: 64,
      description:
        'Obtener valores de transacción'
    },
    UPDATE_FILL_LATER_VARIABLES: {
      id: 'update_fill_later_variables',
      value: 128,
      description:
        'Permite actualizar el valor de las variables con la propiedad "fill_later" activa'
    },
  },
  API_ENQUIRY_PERMISSIONS: {
    MACROSTATE: {
      id: 'macrostate',
      value: 1,
      description:
        'Consultar el macroestado de la transacción'
    },
    TIMELINE: {
      id: 'timeline',
      value: 2,
      description:
        'Consultar el timeline de la transacción'
    },
    TRANSACTION_DETAILS: {
      id: 'transaction_details',
      value: 4,
      description:
        'Consultar los detalles de la transacción'
    },
    INTERVENER_LIST: {
      id: 'intervener_list',
      value: 8,
      description:
        'Consultar los intervinientes de la transacción'
    },
    DOWNLOAD_CERTIFICATE: {
      id: 'download_certificate',
      value: 16,
      description:
        'Listar y descargar documentos'
    },
    CALLBACKS_HISTORY: {
      id: 'callbacks_history',
      value: 32,
      description:
        'Histórico de callbacks'
    },
    DOCUMENT: {
      id: 'document',
      value: 64,
      description:
        'Documento único'
    }
  },

  FIELD_TYPES: {
    TEXT: 'text',
    JSON: 'json',
    BOOLEAN: 'boolean',
    TEMPLATES: 'templates',
    APPEARANCES: 'appearances',
    BILLING_UNITS: 'billing_units',
    LANGUAGES: 'languages',
    TRANSACTION_LANGUAGES: 'transaction_languages',
    COMMSTRACKER_URL: 'commstracker_url',
    EMAIL_PROVIDER: 'email_provider',
    SMS_PROVIDER: 'sms_provider',
    POSTAL_ENVELOPE: 'postal_envelope',
    EMAIL_STREAM: 'email_stream_type',
    AUTH_METHOD: 'auth_method',
    B2B_IMAGES: 'b2b_images',
    B2B_TAGS: 'b2b_tags',
    B2B_TRANSLATION_CATEGORIES: 'text',
    B2B_TRANSLATION_IDS: 'text',
    PDF_FORMATS: 'pdf_formats',
    CERTIFICATION_ENTITIES: 'certification_entities',
    CERTIFICATE_ISSUERS: 'certificate_issuers',
    SEAL_PROVIDERS: 'seal_providers',
    TSA_PROVIDERS: 'tsa_providers',
    CERTIFICATE_PROVIDERS: 'certificate_providers',
    NUMBER: 'number'
  },
  BOOLEAN_VALUES: [{
    code: '1',
    icon: 'check_circle',
    text: 'Activado'
  },
    {code: '0', icon: 'cancel', text: 'Desactivado'}],
  STREAM_TYPES: [
    {code: 'transactional', text: 'Transaccional'},
    {
      code: 'commercial',
      text: 'Comercial'
    }],
  SMS_PROVIDERS: [
    {code: null, text: 'Ninguno'},
    {code: 'altiria', text: 'Altiria'},
    {code: 'link_mobility', text: 'Link Mobility'},
    {code: 'nrs', text: 'NRS'}
  ],
  CERTIFICATION_ENTITIES: [
    {code: 'customercomms', text: 'Customer Comms'},
    {code: 'customercomms-fp', text: 'Customer Comms FP'},
    {code: 'mailteck', text: 'MailTeck'},
    {code: 'mailteck-fp', text: 'MailTeck FP'}
  ],
  PDF_FORMATS: [
    {code: 'PDFA', text: 'PDFA'},
    {code: 'PDF17', text: 'PDF 1.7'}
  ],
  EMAIL_PROVIDERS: [
    {code: null, text: 'Ninguno'},
    {code: 'mailjet', text: 'Mailjet'},
    {code: 'ses', text: 'Amazon SES'}
  ],

  B2B_AVAILABLE_IMAGES: [
    'burofax-certificada',
    'carta',
    'carta-certificada',
    'carta-ordinaria',
    'email-certificado',
    'email-ordinario',
    'email-sello-tiempo',
    'firma-biometrica-avanzada',
    'firma-simple',
    'firma-simple-OTP',
    'inese-2020',
    'inese-2020-noid',
    'pub-web-cert',
    'pub-web-cert-email',
    'pub-web-cert-sms',
    'pub-web-email',
    'pub-web-regis',
    'pub-web-regis-email',
    'pub-web-regis-sms',
    'pub-web-sms',
    'sms-certificado',
    'sms-ordinario'
  ],
  B2B_AVAILABLE_TAGS: ['SMS', 'EMAIL', 'POSTAL'],
  CONFIG_SECTIONS: [],

  AUTH_METHODS: [
    {
      text: 'Ninguno',
      code: 'NONE'
    },
    {
      text: 'Basic Auth',
      code: 'BASIC'
    },
    {
      text: 'Oauth2',
      code: 'OAUTH2'
    },
    {
      text: 'JWT',
      code: 'JWT'
    },
    {
      text: 'JWT-CERT',
      code: 'JWT-CERT'
    },
  ],

  /* Para borrar */
  ESPECIAL_TEMPLATE_CODE: 'templateCode',

}
constants.CONFIG_SECTIONS.push(...[
  {
    id: 'overview',
    title: 'General',
    icon: 'import_contacts',
    description:
      'Configuración de los parámetros generales del proceso',
    properties: [
      {
        id: 'name',
        name: 'Nombre del proceso',
        description:
          'Establece el nombre del proceso. Este servirá como ayuda al código de proceso. Debe ser corto y claro, diferenciandose de los procesos de su misma plantilla.' +
          ' <strong>No puede superar los  60 caracteres.</strong>',
        type: 'text',
        extra: {
          type: 'text',
          maxLength: 60
        }
      },
      {
        id: constants.TEMPLATE_CODE,
        name: 'Plantilla asociada',
        description: 'Selecciona la plantilla asociada. <strong>Si el proceso tiene instancias no se puede cambiar.</strong>',
        type: constants.FIELD_TYPES.TEMPLATES,
      },
      {
        id: constants.APPEARANCE_CODE,
        name: 'Apariencia asociada',
        description: 'Selecciona la apariencia del proceso. <strong>Sustituye al de la cuenta raíz.</strong>',
        type: constants.FIELD_TYPES.APPEARANCES,
      },
      {
        id: constants.DEFAULT_LANGUAGE,
        name: 'Idioma por defecto',
        description:
          'Idioma por defecto con el que se lanzarán las instancias del proceso.',
        type: constants.FIELD_TYPES.LANGUAGES,
      },
      {
        id: constants.CUSTOM_FR_DOMAIN,
        name: 'Dominio de acceso del Front Renderer',
        description:
          'Dominio por el que se accederá a Front Renderer. <strong>Si no se rellena, se usará el dominio por defecto (landing-cs).</strong>',
        type: constants.FIELD_TYPES.TEXT,
      },
      {
        id: constants.CUSTOM_FW_DOMAIN,
        name: 'Dominio de acceso del Framework Web (antiguo)',
        description: 'Dominio por el que se accederá al Framework Web. <strong>Si no se rellena, se usará el dominio por defecto (landing).</strong>',
        type: constants.FIELD_TYPES.TEXT,
      },
      {
        id: constants.COMMUNICALIA_SYNC,
        name: 'Sincronización con Communicalia',
        description:
          'Indica si el proceso se debe sincronizar con Communicalia ',
        type: constants.FIELD_TYPES.BOOLEAN,
      },
      {
        id: constants.MACROSTATUS_CALLBACKS,
        name: 'Callbacks en cambio de macroestado',
        description:
          'Activa los callbacks en los cambios de macroestado',
        type: constants.FIELD_TYPES.BOOLEAN,
      },
      {
        id: constants.STOPPED,
        name: '¿Proceso parado?',
        description:
          'Indica si el proceso se encuentra activo o parado. <strong>Cuidado, esto bloqueará el proceso</strong>',
        type: constants.FIELD_TYPES.BOOLEAN
      },
    ],
  },
  {
    id: 'certificate',
    title: 'Certificados',
    icon: icons.I_CERTIFICATE,
    description:
      'Configuración de los certificados del proceso',
    properties: [
      {
        id: constants.CERTIFICATE_PROVIDER,
        name: 'Proveedor del certificado',
        description:
          'Indica el proveedor que emitirá el certificado',
        type: constants.FIELD_TYPES.CERTIFICATE_PROVIDERS,
      },
      {
        id: constants.SEAL_PROVIDER,
        name: 'Proveedor de sellos',
        description:
          'Indica el proveedor de los sellos',
        type: constants.FIELD_TYPES.SEAL_PROVIDERS,
      },
      {
        id: constants.TSA_PROVIDER,
        name: 'Proveedor de sellos de tiempo (TSA)',
        description:
          'Indica el proveedor de los sellos de tiempo (TSA)',
        type: constants.FIELD_TYPES.TSA_PROVIDERS,
      },
      {
        id: constants.CERT_EXPIRATION_DAYS,
        name: 'Días de expiración de los certificados',
        description:
          'Selecciona los días de custodia del certificado',
        type: constants.FIELD_TYPES.NUMBER,
      },
      {
        id: constants.QUALIFIED_TEXTS,
        name: '¿Usa textos cualificados?',
        description:
          'Indica si el certificado es de un proceso cualificado',
        type: constants.FIELD_TYPES.BOOLEAN,
      },
      {
        id: constants.CERTIFICATE_ISSUER,
        name: 'Emisor del certificado',
        description:
          'Empresa que emite el certificado. No confundir con la entidad certificadora.',
        type: constants.FIELD_TYPES.CERTIFICATE_ISSUERS,
      },
      {
        id: constants.CERTIFICATE_LANGUAGE,
        name: 'Idioma del certificado',
        description:
          'Idioma de generación del certificado.',
        type: constants.FIELD_TYPES.TRANSACTION_LANGUAGES,
      },
      {
        id: constants.FORMAT_PDF_CERTIFICATES,
        name: 'PDF Certificados',
        description:
          'Formato de PDF de los certificados.',
        type: constants.FIELD_TYPES.PDF_FORMATS,
      },
      {
        id: constants.FORMAT_PDF_EVENTS,
        name: 'PDF Eventos',
        description:
          'Formato de PDF de los eventos.',
        type: constants.FIELD_TYPES.PDF_FORMATS,
      },
      {
        id: constants.CERT_ENTITY_NAME,
        name: 'Entidad certificadora (deprecated)',
        disabled: true,
        description:
          '(deprecated) Entidad certificadora del certificado de evidencias certificables.',
        type: constants.FIELD_TYPES.CERTIFICATION_ENTITIES,
      },
    ],
  },
  {
    id: 'email_provider',
    title: 'Proveedor email',
    icon: 'email',
    description:
      'Configuración del proveedor de email del proceso',
    properties: [
      {
        id: constants.EMAIL_ENTITY,
        name: 'Proveedor',
        description:
          'Proveedor asignado para el servicio de email',
        type: constants.FIELD_TYPES.EMAIL_PROVIDER
      },
      {
        id: constants.EMAIL_LOGIN,
        name: 'Login de proveedor',
        description:
          'Usuario de login del servicio del proveedor',
        type: constants.FIELD_TYPES.TEXT,
        extra: {
          type: 'text',
          maxLength: 255
        }
      },
      {
        id: constants.EMAIL_PASSWORD,
        name: 'Contraseña de proveedor',
        description:
          'Contraseña de login del servicio del proveedor',
        type: constants.FIELD_TYPES.TEXT,
        extra: {
          type: 'text',
          maxLength: 255
        }
      },
      {
        id: constants.EMAIL_STREAM_TYPE,
        name: 'Tipo de email',
        description:
          'Tipo de email, véase sea comercial o transaccional',
        type: constants.FIELD_TYPES.EMAIL_STREAM
      },
      {
        id: constants.COMMSTRACKER_EMAIL,
        name: 'Url Commstracker email',
        description:
          'Url acortada para los emails',
        type: constants.FIELD_TYPES.COMMSTRACKER_URL
      },
    ],
  },
  {
    id: 'sms_provider',
    title: 'Proveedor SMS',
    icon: 'sms',
    description:
      'Configuración del proveedor de SMS',
    properties: [
      {
        id: constants.SMS_PROVIDER,
        name: 'Servicio de SMS',
        type: constants.FIELD_TYPES.SMS_PROVIDER
      },
      {
        id: constants.COMMSTRACKER_SMS,
        name: 'Url Commstracker SMS',
        type: constants.FIELD_TYPES.COMMSTRACKER_URL
      },
    ],
  },
  {
    id: 'postal_provider',
    title: 'Proveedor Postal',
    icon: icons.I_POSTAMAIL,
    description:
      'Configuración del proveedor postal',
    properties: [
      {
        id: constants.POSTAL_ENVELOPE,
        name: 'Sobre postal',
        description: 'Configuración del sobre seleccionado',
        type: constants.FIELD_TYPES.POSTAL_ENVELOPE
      },
    ],
  },
  {
    id: 'whatsapp_provider',
    title: 'Proveedor Whatsapp',
    icon: icons.I_WHATSAPP,
    description:
      'Configuración del proveedor de Whatsapp',
    properties: [
      {
        id: constants.WHATSAPP_SENDER,
        name: 'Sender',
        description: 'Configuración del sender de Whatsapp',
        type: constants.FIELD_TYPES.TEXT
      },
      {
        id: constants.WHATSAPP_API_ID,
        name: 'ID Api',
        description: 'Configuración del ID API Whatsapp',
        type: constants.FIELD_TYPES.TEXT
      },
      {
        id: constants.WHATSAPP_CLIENT_ID,
        name: 'Client ID',
        description: 'Configuración del Client ID Whatsapp',
        type: constants.FIELD_TYPES.TEXT
      },
      {
        id: constants.WHATSAPP_CLIENT_SECRET,
        name: 'Client Secret',
        description: 'Configuración del Client Secret de Whatsapp',
        type: constants.FIELD_TYPES.TEXT
      },
    ],
  },
  {
    id: 'billing',
    title: 'Facturación',
    icon: 'attach_money',
    description:
      'Facturación del proceso',
    properties: [
      {
        id: constants.DEFAULT_CECO,
        name: 'Unidad de facturación',
        type: constants.FIELD_TYPES.BILLING_UNITS,
        description:
          'Unidad de facturación por defecto (ceco)',
      },
      {
        id: constants.DEFAULT_ANAL_UNIT,
        name: 'Unidad de analítica por defecto',
        type: constants.FIELD_TYPES.TEXT,
        description:
          'Unidad analítica por defecto',
      },
      {
        id: constants.OF,
        name: 'OF',
        type: constants.FIELD_TYPES.TEXT,
        description:
          'Orden de fabricación del proceso',
      },
      {
        id: constants.CLIENT_ID,
        name: 'ID Cliente',
        type: constants.FIELD_TYPES.TEXT,
        group: 'billing_config',
        description:
          'ID del cliente',
      },
      {
        id: constants.NAVISION_ID,
        name: 'ID Navision',
        type: constants.FIELD_TYPES.TEXT,
        group: 'billing_config',
        description:
          'ID de Navision',
      },
      {
        id: constants.SEG01,
        name: 'Segmento 01',
        type: constants.FIELD_TYPES.TEXT,
        group: 'billing_config',
        description:
          'Segmento 01',
      }, {
        id: constants.SEG02,
        name: 'Segmento 02',
        type: constants.FIELD_TYPES.TEXT,
        group: 'billing_config',
        description:
          'Segmento 02',
      }, {
        id: constants.SEG03,
        name: 'Segmento 03',
        type: constants.FIELD_TYPES.TEXT,
        group: 'billing_config',
        description:
          'Segmento 03',
      }, {
        id: constants.SEG04,
        name: 'Segmento 04',
        type: constants.FIELD_TYPES.TEXT,
        group: 'billing_config',
        description:
          'Segmento 04',
      }, {
        id: constants.SEG05,
        name: 'Segmento 05',
        type: constants.FIELD_TYPES.TEXT,
        group: 'billing_config',
        description:
          'Segmento 05',
      }, {
        id: constants.SEG06,
        name: 'Segmento 06',
        type: constants.FIELD_TYPES.TEXT,
        group: 'billing_config',
        description:
          'Segmento 06',
      }, {
        id: constants.COMPONENTS_MAPPING,
        name: 'Mapeo de componentes',
        type: null,
        group: 'billing_config',
        description:
          'Configuración del mapeo de componentes de facturación',
      }
    ],
  },
  {
    id: 'b2b',
    title: 'Portal B2B',
    icon: icons.I_B2B,
    description:
      'Configuración del proceso en el portal B2B',
    properties: [
      {
        id: constants.IS_B2B,
        name: '¿Activo en B2B?',
        description: 'Si esta propiedad está activa, el proceso será visible en el portal B2B',
        type: constants.FIELD_TYPES.BOOLEAN
      },
      {
        id: constants.B2B_BEHAVIOR_LAYER,
        name: 'Behavior layer',
        description: 'JSON de configuración del portal',
        type: constants.FIELD_TYPES.JSON
      },
      {
        id: constants.B2B_IMAGE,
        name: 'Imagen del proceso',
        description: 'Imagen representativa del proceso',
        type: constants.FIELD_TYPES.B2B_IMAGES
      },
      {
        id: constants.B2B_TAGS,
        name: 'Etiquetas del proceso',
        description: 'Etiquetas de los canales de comunicación del proceso',
        type: constants.FIELD_TYPES.B2B_TAGS
      },
      {
        id: constants.B2B_TRANSLATION_CATEGORY,
        name: 'ID Traducción (Categoría)',
        description: 'ID de la traducción de la <strong>categoría</strong> que se verá en el portal',
        type: constants.FIELD_TYPES.TEXT,
        extra: {
          allow_empty: false
        }
      },
      {
        id: constants.B2B_TRANSLATION_ID,
        name: 'ID Traducción (Proceso)',
        description: 'ID de la traducción del <strong>proceso</strong> que se verá en el portal',
        type: constants.FIELD_TYPES.TEXT,
        extra: {
          allow_empty: false
        }
      },
    ],
  },
  {
    id: 'api',
    title: 'Interacción API',
    icon: 'api',
    description:
      'Configuración del API de intromisión y de consulta',
    properties: [
      {
        id: constants.API_INTROMISSION,
        name: 'Api de intromisión',
        description: 'Configuración de la interacción externa con el proceso a través de API'
      },
      {
        id: constants.API_ENQUIRY,
        name: 'Api de consulta',
        description: 'Configuración de la consulta externa con el proceso a través de API'
      },
    ],
  },
  {
    id: 'security',
    title: 'Seguridad',
    icon: 'security',
    description:
      'Configuración de la seguridad y el método de autenticación',
    properties: [
      {
        id: constants.AUTHENTICATION_METHOD,
        name: 'Método de autenticación',
        description: 'Configuración del método de autenticación que será requerido a través de <strong>API</strong> en el proceso',
        type: constants.FIELD_TYPES.AUTH_METHOD
      },
    ],
  },
  {
    id: 'events',
    title: 'Eventos',
    icon: 'event',
    description:
      'Configuración de los eventos del proceso'
  },
  {
    id: 'callbacks',
    title: 'Callbacks',
    icon: 'phone',
    description:
      'Configuración de los callbacks del proceso'
  }
])


export default Object.freeze(constants)
