<template>
  <v-overlay
    v-if="true"
    :absolute="smallScreen"
    :color="background"
    class="cb-loading-div"
    opacity="1"
    z-index="999"
  >
    <v-row justify="center">

      <span
        class="cb-font-size-2 bg_dark--text"
        v-html="message"
      ></span>
    </v-row>
    <v-row
      class="mt-4"
      justify="center"
    >
      <v-col cols="2">
        <v-progress-circular
          color="primary"
          indeterminate
          size="80"
          width="8"
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-overlay>
</template>

<script>
export default {
  name: 'LoadingContainer',
  props: {
    message: String,
    background: {
      type: String,
      default: 'white'
    },
    smallScreen: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang="scss">
@import "src/assets/styles/resources/colors";

.cb-loading-div {
  z-index: 1000 !important;
}

</style>
