<template>
  <tr
    ref="process_row"
    class="cb-data-table-row"
  >
    <td :style="helpers_resolution.getColWidth('15%')">
            <span
              :title="item['username']"
              class="text-truncate"
            >
              <v-icon
                :color="status.color"
                :style="'min-width:24px; width:14px;font-size: '+status.size+'px !important;'"
                class="mx-auto"
              >{{status.icon}}
              </v-icon>
              <a
                title="Abrir usuario"
                @click="open()"
              >
                <span class="ml-1 my-auto">
                {{item['username']}}
                </span>
              </a>
            </span>
    </td>
    <td :style="helpers_resolution.getColWidth('15%')">
            <span
              :title="item['description']"
              class="text-truncate"
            >

                <span class="ml-1 my-auto">
                {{item['description']}}
                </span>
            </span>
    </td>
    <td :style="helpers_resolution.getColWidth('15%')">
            <span
              :title="item['expiration']"
              class="text-truncate"
            >

                <span class="ml-1 my-auto">
                {{$moment.unix(item['expiration']).format('DD/MM/YY')}}
                </span>
            </span>
    </td>
    <td :style="helpers_resolution.getColWidth('15%')">
            <span
              :title="item['active']"
              class="text-truncate"
            >

                <span class="ml-1 my-auto">{{item['active'] == 1 ? 'Sí' : 'No'}}
                </span>
            </span>
    </td>
    <td :style="helpers_resolution.getColWidth('15%')">
            <span
              :title="item['group_name']"
              class="text-truncate"
            >

                <span class="ml-1 my-auto">{{item['group_name']}}
                </span>
            </span>
    </td>
    <td
      :style="helpers_resolution.getColWidth('actions')"
      class="pa-0"
    >

      <menu-model-a
        :actions="actions"
        :icon="$icons.I_ACTIONS"
        @actionLaunched="actionLaunched"
      />
    </td>
  </tr>

</template>

<script>
import model_template from '@/constants/models/model_template'
import helpers_resolution from '@/plugins/helpers_resolution'
import MenuModelA from '@/components/repository/buttons/MenuModelA'
import service_user from '@/services/service_user'
import {P_PROCESSES} from '@/constants/group_permissions'
import vuex_processes from '@/store/modules/sections/vuex_processes'
import model_process from '@/constants/models/model_process'
import helpers from '@/plugins/helpers'

export default {
  name: 'DevUserRow',
  components: {MenuModelA},
  props: {item: Object, headers: Array, visualOptions: Object},
  data() {
    return {
      model_template,
      helpers_resolution
    }
  },
  computed: {
    actions() {
      let actions = []

      if (service_user.checkGroupPermission(P_PROCESSES)) {
        actions.push({
          id: 'processes',
          icon: this.$icons.I_PROCESS,
          title: 'Ver procesos',
          enabled: true,
        })
      }
      return actions
    },
    status() {

      let status = {
        size: 16,
        color: 'success',
        icon: 'circle'
      }


      if (!this.item.active) {
        status.color = 'contrast_medium'
      }


      return status
    }
  },
  methods: {
    open() {
      this.$emit('openItem', this.item)
    },

    actionLaunched(id) {
      switch (id) {
        case 'processes': {
          this.$store.dispatch(vuex_processes.constants.NAMESPACE + '/' +
            vuex_processes.constants.A_SET_SEARCH_FILTERS, {
            [model_process.TEMPLATE_CODE]: this.item[model_template.CODE]
          }).then(() => {
            helpers.open('processes')
          })
          break
        }
      }
    },
  }
}
</script>

<style lang="scss">
@import "../../../../assets/styles/resources/colors";
@import "../../../../assets/styles/resources/variables";

.cb-table-chip-version {
  min-width: 60px;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 10px;
}


.cb-no-bordered {
  border: none !important;
}

</style>
