const constants = {
  ID: 'id',

  ENTITY_NAME: 'masteraccount',
  NAME: 'name',
  CODE: 'code',

  PROCESSES: 'processes',
  SUBACCOUNTS: 'subaccounts',

  DEFAULT_LANG: 'default_language',

  POSTAMAIL_USER: 'postamail_user',
  POSTAMAIL_PASSWORD: 'postamail_password',

  POSTAMAIL_SFTP_USER: 'postamail_sftp_user',
  POSTAMAIL_SFTP_PASSWORD: 'postamail_sftp_password',

  VISIBLE_B2B: 'visible_b2b',
  SUBDOMAIN_B2B: 'subdomain_b2b',
  PRIMARY_COLOR: 'primary_color',
  SUPPORT_EMAIL: 'support_email',
  FEDERATED_B2B: 'federated_b2b',

  APPEARANCE_NAME: 'appearance_name',
  APPEARANCE_CODE: 'appearance_code',

  USERS_B2B: 'users_b2b',
  GROUPS_B2B: 'groups_b2b',
  BILLING_UNITS: 'billing_units',

  NOTARIAL_DEPOSIT: 'notarial_deposit',

  /* Para borrar */
  ESPECIAL_ENTITY_NAME: 'master_account',

  getTableColsWidth(col) {
    const widths = {
      name: '30%',
      postamail_user: '20%',
      postamail_password: '20%',
      postamail_sftp_user: '15%',
      postamail_sftp_password: '15%',
    }

    return widths[col]
  },

}

constants.CONFIG_SECTIONS = [
  {
    id: 'postamail_edit',
    title: 'General',
    description: 'Configuración masteraccount postamail',
    properties: [
      {
        id: constants.POSTAMAIL_USER,
        name: 'Usuario',
        description: 'Usuario Postamail',
        type: 'postamail_user'
      },
      {
        id: constants.POSTAMAIL_PASSWORD,
        name: 'Contraseña',
        description: 'Contraseña Postamail',
        type: 'postamail_password'
      },
      {
        id: constants.POSTAMAIL_SFTP_USER,
        name: 'Usuario SFTP',
        description: 'Usuario SFTP',
        type: 'postamail_sftp_user',

      },
      {
        id: constants.POSTAMAIL_SFTP_PASSWORD,
        name: 'Contraseña SFTP',
        description: 'Contraseña SFTP',
        type: 'postamail_sftp_password',

      },
    ]

  }
]


export default constants
